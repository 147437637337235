
export default {
  name: "seasonmegabar",
  data() {
   return {
     beforeCity: '',
     beforeBrand: '',
     seasons: [],
     filter: {
       season: ''
     }
   }
  },
  async mounted() {
    this.filter.season = ''
    if (this.$store.state.megafilter.filter.city) {
      this.beforeCity = this.$store.state.megafilter.filter.city.name
    }
    if (this.$store.state.megafilter.filter.brand) {
      this.beforeBrand = this.$store.state.megafilter.filter.brand.name
    }

    var seasons = await this.$http.$get(`/tire/listing/seasons`)
    if (seasons.status) {
      this.seasons = seasons.data.tireSeasons.reverse()
    }
  },
  methods: {
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    },
    setFilter(season) {
      if (season) {
        this.filter.season = season
      }
      this.$store.commit('megafilter/setFilterSeason', this.filter.season)
      this.$nuxt.$emit('setStepComponent', 3)
    },
  }
}
