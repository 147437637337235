
import Citymegabar from "@/components/search/citymegabar";
import Seasonmegabar from "@/components/search/seasonmegabar";
import Widthmegabar from "@/components/search/widthmegabar";
import Heightmegabar from "@/components/search/heightmegabar";
import Radiusmegabar from "@/components/search/radiusmegabar";
import Runflatmegabar from "@/components/search/runflatmegabar";
export default {
  name: "searchmegamenu",
  components: {Runflatmegabar, Radiusmegabar, Heightmegabar, Widthmegabar, Seasonmegabar, Citymegabar},
  created() {
    this.$nuxt.$on('setStepComponent',(key) => {
      this.setStep(key)
    })

    if (this.isLanding) {
      this.step = 3
    }
  },
  data() {
    return {
      step: 1
    }
  },
  mounted() {
    if (this.citySlug && this.brand) {
      this.$store.state.megafilter.filter.city.slug = this.citySlug
      this.$store.state.megafilter.filter.brand.slug = this.brandSlug
    }
  },
  props: {
    isLanding: {
      type: Boolean,
      default: false
    },
    brandSlug: {
      type: String,
      default: ''
    },
    citySlug: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeSearchBar() {
      document.getElementById('myNav1').style.width = "0%";
      this.step = 1
      this.$store.commit('megafilter/setFiltersBrand', {})
      this.$store.commit('megafilter/setFilterCity', {})
      this.$store.commit('megafilter/setFilterWidth', '')
      this.$store.commit('megafilter/setFilterHeight', '')
      this.$store.commit('megafilter/setFilterRadius', '')
      this.$store.commit('megafilter/setFilterRunFlat', '')
      this.$store.commit('megafilter/setFiltersBrand', [])
    },
    setStep(key) {
      this.step = key
    },
    beforeStep() {
      if (this.step <= 1) {
        this.step = 1
      } else {
        this.step = this.step - 1
      }
    }
  }
}
