
export default {
  name: "heightmegabar",
  data() {
    return {
      search: '',
      beforeCity: '',
      beforeBrand: '',
      beforeSeason: '',
      beforeWidth: '',
      heights: [],
      isNull: true,
      control: true,
      filter: {
        height: ''
      }
    }
  },
  computed: {
    filteredHeight(){
      return this.heights.filter( item => item.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
  },
  methods: {
    changeHeight() {
      this.control = true
      if (this.search.length === 2) {
        if (this.heights.includes(parseInt(this.search))) {
          this.$store.commit('megafilter/setFilterHeight', this.search.toString())
          this.$nuxt.$emit('setStepComponent', 5)
        } else {
          this.control = false
        }
      } else {
        if (this.filteredHeight.length === 0) {
          this.control = false
        }
      }
    },
    remove(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.search === '') {
          this.setStep(4)
        }
      }
    },
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    },
    setFilter(key) {
      this.filter.height = key
      this.search = key.toString()
      this.$nuxt.$emit('setStepComponent', 5)
      this.$store.commit('megafilter/setFilterHeight', key.toString())
    },
  },
  async mounted() {
    this.$refs.heightFocus.focus()
    if (this.$store.state.megafilter.filter.city) {
      this.beforeCity = this.$store.state.megafilter.filter.city.name
    }
    if (this.$store.state.megafilter.filter.brand) {
      this.beforeBrand = this.$store.state.megafilter.filter.brand.name
    }

    if (this.$store.state.megafilter.filter.season) {
      this.beforeSeason = this.$store.state.megafilter.filter.season.name
    }

    if (this.$store.state.megafilter.filter.width) {
      this.beforeWidth = this.$store.state.megafilter.filter.width.toString()
    }

    var heights = await this.$http.$get(`/tire/listing/heights?width=${this.beforeWidth}`)
    if (heights.status) {
      this.heights = heights.data.tireProductHeights
    }
  },
}
