
export default {
  name: "radiusmegabar",
  data() {
    return {
      search: '',
      beforeCity: '',
      beforeBrand: '',
      beforeSeason: '',
      beforeWidth: '',
      beforeHeight: '',
      radiuses: [],
      control: true,
      filter: {
        radius: ''
      }
    }
  },
  methods: {
    setFilter(key) {
      this.filter.radius = key
      this.search = key.toString()
      this.$nuxt.$emit('setStepComponent', 6)
      this.$store.commit('megafilter/setFilterRadius', key.toString())
    },
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    },
    changeRadius() {
      this.control = true
      if (this.search.length === 2) {
        if(this.radiuses.includes(parseInt(this.search))) {
          this.$store.commit('megafilter/setFilterRadius', this.search.toString())
          this.$nuxt.$emit('setStepComponent', 6)
        } else {
          this.control = false
        }
      } else {
        if (this.filteredRadiuses.length === 0) {
          this.control = false
        }
      }
    },
    remove(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.search === '') {
          this.setStep(5)
        }
      }
    },
  },
  computed: {
    filteredRadiuses(){
      return this.radiuses.filter( item => item.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
  },
  async mounted() {
    this.$refs.focusRadius.focus()
    if (this.$store.state.megafilter.filter.city) {
      this.beforeCity = this.$store.state.megafilter.filter.city.name
    }
    if (this.$store.state.megafilter.filter.brand) {
      this.beforeBrand = this.$store.state.megafilter.filter.brand.name
    }

    if (this.$store.state.megafilter.filter.season) {
      this.beforeSeason = this.$store.state.megafilter.filter.season.name
    }

    if (this.$store.state.megafilter.filter.width) {
      this.beforeWidth = this.$store.state.megafilter.filter.width.toString()
    }

    if (this.$store.state.megafilter.filter.height) {
      this.beforeHeight = this.$store.state.megafilter.filter.height.toString()
    }

    var radiuses = await this.$http.$get(`/tire/listing/radiuses?width=${this.beforeWidth}&height=${this.beforeHeight}`)
    if (radiuses.status) {
      this.radiuses = radiuses.data.tireProductRadiuses
    }
  },
}
