
export default {
  name: "runflatmegabar",
  data() {
    return {
      brand: "",
      search: '',
      beforeCity: '',
      beforeBrand: '',
      beforeSeason: '',
      beforeWidth: '',
      beforeHeight: '',
      beforeRadius: '',
      filter: {
        run_flat: ''
      }
    }
  },
  computed: {
    city: {
      get() {
        return this.$store.state.city.slug
      },
      set(value) {
        localStorage.setItem("city", value)
        this.$cookies.set('city', value, {
          sameSite: 'None'
        });
        this.$store.commit('city/setCitySlug', value)
      },
    }
  },
  props: {
    isLanding: {
      type: Boolean,
      default: false
    },
    brandSlug: {
      type: String,
      default: ''
    },
    citySlug: {
      type: String,
      default: ''
    }
  },
  methods: {
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    },
    setFilter(key) {
      this.filter.run_flat = key
      this.$store.commit('megafilter/setFilterRunFlat', key)
      document.getElementById('myNav1').style.width = "0%";
      if (this.citySlug) {
        if (this.citySlug !== 'not_defined') {
          this.city = this.citySlug
        }
      } else {
        this.city = this.$store.state.megafilter.filter.city.slug
      }

      if (this.brandSlug) {
        this.brand = this.brandSlug
      } else {
        this.brand = this.$store.state.megafilter.filter.brand.slug
      }
      if (this.city) {
        if (this.filter.run_flat) {
          this.$router.push(this.localePath({
            name: 'products',
            params: {brand: 'michelin'},
            query: {
              city: this.city,
              season: this.$store.state.megafilter.filter.season.code,
              width: this.$store.state.megafilter.filter.width,
              height: this.$store.state.megafilter.filter.height,
              radius: this.$store.state.megafilter.filter.radius,
              run_flat: this.$store.state.megafilter.filter.run_flat === true ? '1' : '0'
            }
          }))
        } else {
          this.$router.push(this.localePath({
            name: 'products',
            params: {brand: 'michelin'},
            query: {
              city: this.city,
              season: this.$store.state.megafilter.filter.season.code,
              width: this.$store.state.megafilter.filter.width,
              height: this.$store.state.megafilter.filter.height,
              radius: this.$store.state.megafilter.filter.radius,
            }
          }))
        }
      } else {
        if (this.filter.run_flat) {
          this.$router.push(this.localePath({
            name: 'products',
            params: {brand: 'michelin'},
            query: {
              season: this.$store.state.megafilter.filter.season.code,
              width: this.$store.state.megafilter.filter.width,
              height: this.$store.state.megafilter.filter.height,
              radius: this.$store.state.megafilter.filter.radius,
              run_flat: this.$store.state.megafilter.filter.run_flat === true ? '1' : '0'
            }
          }))
        } else {
          this.$router.push(this.localePath({
            name: 'products',
            params: {brand: 'michelin'},
            query: {
              season: this.$store.state.megafilter.filter.season.code,
              width: this.$store.state.megafilter.filter.width,
              height: this.$store.state.megafilter.filter.height,
              radius: this.$store.state.megafilter.filter.radius,
            }
          }))
        }
      }

      this.$store.commit('megafilter/setFiltersBrand', {})
      this.$store.commit('megafilter/setFilterCity', {})
      this.$store.commit('megafilter/setFilterWidth', '')
      this.$store.commit('megafilter/setFilterHeight', '')
      this.$store.commit('megafilter/setFilterRadius', '')
      this.$store.commit('megafilter/setFilterRunFlat', '')
    }
  },
  async mounted() {
    if (!this.brandSlug && !this.citySlug) {
      if (this.$store.state.megafilter.filter.city) {
        this.beforeCity = this.$store.state.megafilter.filter.city.name
      }
      if (this.$store.state.megafilter.filter.brand) {
        this.beforeBrand = this.$store.state.megafilter.filter.brand.name
      }
    }

    if (this.$store.state.megafilter.filter.season) {
      this.beforeSeason = this.$store.state.megafilter.filter.season.name
    }

    if (this.$store.state.megafilter.filter.width) {
      this.beforeWidth = this.$store.state.megafilter.filter.width.toString()
    }

    if (this.$store.state.megafilter.filter.height) {
      this.beforeHeight = this.$store.state.megafilter.filter.height.toString()
    }

    if (this.$store.state.megafilter.filter.radius) {
      this.beforeRadius = this.$store.state.megafilter.filter.radius.toString()
    }
  },
}
