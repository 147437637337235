
export default {
  name: "citymegabar",
  data() {
    return {
      beforeFilter: '',
      cities: [],
      filter: {
        city: '',
      },
      search: '',
      dropdown: false
    }
  },
  async mounted() {
    var cities = await this.$http.$get('/regions/1/cities-with-products')
    if (cities.status) {
      this.cities = cities.data.cities
    }
  },
  computed: {
    filteredCity(){
      if(this.search.trim().length > 1){
        return this.cities.filter( item => item.name.toLocaleUpperCase('tr-TR').indexOf(this.search.toLocaleUpperCase('tr-TR')) > -1)
      }
    },
  },
  methods: {
    setFilter(city) {
      this.filter.city = city
      this.$store.commit('megafilter/setFilterCity', city)
      this.search = this.filter.city.name
      this.$nuxt.$emit('setStepComponent', 2)
    }
  }
}
