
export default {
  name: "widthmegabar",
  data() {
    return {
      beforeCity: '',
      beforeBrand: '',
      beforeSeason: '',
      widths: [],
      search: '',
      control: true,
      best: [
        {
          value: '175'
        },{
          value: '185'
        },{
          value: '195'
        },{
          value: '205'
        },{
          value: '225'
        },{
          value: '235'
        },{
          value: '245'
        },{
          value: '255'
        }
      ],
      bestTemp: [
        {
          value: '175'
        },{
          value: '185'
        },{
          value: '195'
        },{
          value: '205'
        },{
          value: '225'
        },{
          value: '235'
        },{
          value: '245'
        },{
          value: '255'
        }
      ],
      filter: {
        width: ''
      },
    }
  },
  computed: {
    filteredWidth(){
      if (this.search.trim().length === 3) {
        this.$store.commit('megafilter/setFilterWidth', this.search)
        return []
      } else {
        if (this.search.trim().length === 0) {
          return this.widths.slice(0,10)
        } else if(this.search.trim().length > 0){
          return this.widths.filter( item => item.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        }
      }
    },
  },
  async mounted() {
    this.search = ''
    this.$refs.focusMe.focus()
    if (this.$store.state.megafilter.filter.city) {
      this.beforeCity = this.$store.state.megafilter.filter.city.name
    }
    if (this.$store.state.megafilter.filter.brand) {
      this.beforeBrand = this.$store.state.megafilter.filter.brand.name
    }

    if (this.$store.state.megafilter.filter.season) {
      this.beforeSeason = this.$store.state.megafilter.filter.season.name
    }

    var widths = await this.$http.$get(`/tire/listing/widths`)
    if (widths.status) {
      this.widths = widths.data.tireProductWidths
    }
  },
  methods: {
    seeLess() {
      this.best = this.bestTemp
    },
    seeAll() {
      this.best = []
      this.widths.forEach((width) => {
        this.best.push({
          value: width
        })
      })
    },
    changeWidth() {
      this.control = true
      if (this.search.length === 3) {
        if (this.widths.includes(parseInt(this.search))) {
          this.$store.commit('megafilter/setFilterWidth', this.search)
          this.$nuxt.$emit('setStepComponent', 4)
        } else {
          this.control = false
        }
      } else {
        if (this.filteredWidth.length === 0) {
          this.control = false
        }
      }
    },
    setFilter(key) {
      this.filter.width = key
      this.$store.commit('megafilter/setFilterWidth', key)
    },
    setFilterLink(key) {
      this.filter.width = key
      this.search = key
      this.filteredWidth = []
      this.$store.commit('megafilter/setFilterWidth', key)
      this.$nuxt.$emit('setStepComponent', 4)
    },
    setStep(key) {
      this.$nuxt.$emit('setStepComponent', key)
    },
  }
}
